// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-user-module-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    .aioa_dashboard-user-module-inner-wrapper {
        display: flex;
        border-radius: 1rem;
        overflow: hidden;

        .aioa_dashboard-user-module-form {
            width: 100%;
            flex: 0 0 auto;
            padding: 1.5rem;
            border-radius: 1rem;
            overflow: hidden;

            @include media-breakpoint-up(sm) {
                padding: 5rem;
            }

            @include media-breakpoint-up(xl) {
                width: 50%;
                border-radius: 1rem 0 0 1rem;
            }

            h1 {
                text-align: center;
                margin-bottom: 2rem;
                font-weight: $font-weight-bold;
            }

            .form-group {
                &+.form-group {
                    margin-top: 1.5rem;
                }
            }

            .btn {
                margin-top: 2rem;
            }

            .aioa_dashboard-login-link {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 2rem;

                a {

                    &.aioa_forgot-password-link {
                        font-size: $font-size-sm;
                        text-decoration: none;
                    }

                    &.aioa_free-trial-link {
                        font-size: $font-size-lg;
                        font-weight: $font-weight-semibold;
                    }

                    &+a {
                        margin-top: 2rem;
                    }
                }
            }

        }

        .aioa_dashboard-user-module-video {
            display: none;

            @include media-breakpoint-up(xl) {
                width: 50%;
                flex: 0 0 auto;
                background-color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}