// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-widget-plan-list {
    .row {
        justify-content: center;
    }

    .aioa_dashboard-plan-list-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.8rem;
        margin-top: 1rem;

        ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            border: 1px solid rgba($primary, 0.5);
            border-radius: 5px;

            li {
                padding: 0.5rem;
                background-color: rgba($primary, 0.1);

                .btn:not(.active) {
                    background-color: transparent;
                    color: $black;
                    border-color: transparent;
                    font-weight: $font-weight-normal;
                }
            }
        }
    }

    .aioa_dashboard-widget-plan-box {
        &.card {
            --bs-card-cap-padding-y: 1.5rem;
            --bs-card-cap-padding-x: 1.5rem;
            --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
            --bs-card-cap-bg: #F0F5F9;
            --bs-card-border-radius: 1rem;
            --bs-card-inner-border-radius: 1rem;
            --bs-card-border-width: 0;
            box-shadow: 0 10px 0px $primary;
            position: relative;
            margin-bottom: 10px;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 3px 6px rgba($black, 0.15);
                border-radius: 1rem;
                border: 1px solid rgba($black, 0.05);
            }

            .card-header {
                position: relative;
                border-bottom: 1px solid rgba($black, 0.05);
            }

            .card-body {
                position: relative;
                z-index: 1;

                .aioa_dashboard-widget-plan-box-description {}

                .aioa_dashboard-widget-plan-domain-description {
                    margin-top: 0.5rem;
                }

                .aioa_dashboard-widget-plan-box-price {
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;

                    .aioa_dashboard-widget-plan-box-strike-price {
                        font-size: 1.125rem;
                        font-weight: $font-weight-medium;
                        color: $danger;
                    }

                    .aioa_dashboard-widget-plan-box-offer-price {
                        font-size: 1.25rem;
                        font-weight: $font-weight-bold;
                        color: $primary;
                    }

                    span {}
                }

                .aioa_dashboard-widget-plan-box-btn {
                    .btn {
                        &.btn-lg {
                            --bs-btn-border-radius: 2rem;
                            --bs-btn-padding-x: 2rem;
                            color: $primary;

                            &:hover,
                            &:focus {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.aioa_dashboard-cancel-plan-offer {
    text-align: center;

    .aioa_dashboard-offer-image-wrapper {
        width: 500px;
        margin-inline: auto;
        height: 480px;
        overflow: hidden;
    }

    .aioa_dashboard-offer-price-wrapper {
        .price {
            font-size: 1.5rem;

            .offer-price {
                margin-inline-start: 0.8rem;
                color: $primary;
            }
        }
    }

    .aioa_dashboard-cancel-offer-btn {
        .btn {
            --bs-btn-color: var(--bs-gray-500);
            font-weight: $font-weight-normal;
            letter-spacing: 0;
        }
    }

    .aioa_dashboard-accept-offer-btn {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }
}