// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-table {
    display: flex;
    flex-direction: column;

    .aioa_dashboard-table-tr {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            padding-inline: 1rem;
            flex-direction: row;
        }

        @media (min-width: 1700px) {
            padding-inline: 2rem;
        }

        &:nth-child(odd) {
            @include media-breakpoint-down(xl) {
                background-color: rgba(#F0F5F9, 0.6);
            }
        }

        & .aioa_dashboard-table-th,
        & .aioa_dashboard-table-td {

            line-height: normal;
            line-height: 1.5;

            @include media-breakpoint-down(xl) {
                display: flex;
                width: 100%;
                text-align: start !important;

                .aioa_dashboard-table-td-cell-title {
                    flex: 0 0 auto;
                    width: 140px;
                    font-weight: $font-weight-medium;
                    padding-inline-end: 0.5rem;
                }
            }

            @include media-breakpoint-up(xl) {
                flex: 1 1 auto;
                padding-top: 0.8rem;
                padding-bottom: 0.8rem;
            }


            @media (min-width: 1700px) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            &.aioa_dashboard-table-cell-sidegap {

                @include media-breakpoint-down(xl) {
                    padding: 0.5rem 1rem;
                }

                @include media-breakpoint-up(xl) {
                    padding-inline: 0.5rem;
                }

                @media (min-width: 1700px) {
                    padding-inline: 0.8rem;
                }
            }

            &.tcw-50 {
                @include media-breakpoint-up(xl) {
                    width: 50px;
                    flex: 0 0 auto;
                }

                @media (min-width: 1850px) {}
            }

            &.tcw-100 {
                @include media-breakpoint-up(xl) {
                    width: 70px;
                    flex: 0 0 auto;
                }

                @media (min-width: 1850px) {
                    width: 100px;
                }
            }

            &.tcw-150 {
                @include media-breakpoint-up(xl) {
                    width: 130px;
                    flex: 0 0 auto;
                }

                @media (min-width: 1200px) {
                    width: 120px;
                }

                @media (min-width: 1400px) {
                    width: 110px;
                }

                @media (min-width: 1850px) {
                    width: 150px;
                }
            }

            &.tcw-200 {
                @include media-breakpoint-up(xl) {
                    width: 180px;
                    flex: 0 0 auto;
                }

                @include media-breakpoint-up(xxl) {
                    width: 150px;
                    flex: 0 0 auto;
                }

                @include media-breakpoint-up(xxxl) {
                    width: 200px;
                }
            }

            &.tcw-250 {
                @include media-breakpoint-up(xl) {
                    width: 250px;
                    flex: 0 0 auto;
                }
            }

            &.tcw-150-4-250-1 {
                @include media-breakpoint-up(xl) {
                    width: calc(((150px * 4) + (250px) + (0.4rem * 8)));
                    flex: 0 0 auto;
                }

                strong {
                    border: 1px solid rgba($black, 0.1);
                    padding: 1rem;
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }

            &.tcw-150-6 {
                @include media-breakpoint-up(xl) {
                    width: calc(((150px * 6) + (0.4rem * 10)));
                    flex: 0 0 auto;
                }
            }

            // badge
            .badge {
                font-size: $font-size-base;
                --bs-badge-padding-x: 1rem;
                --bs-badge-padding-y: 0.5rem;
            }
        }
    }

    .aioa_dashboard-table-thead {
        background-color: #F0F5F9;
        border-bottom: 1px solid rgba($black, 0.1);

        @include media-breakpoint-down(xl) {
            display: none;
        }

        .aioa_dashboard-table-tr {
            .aioa_dashboard-table-th {
                font-size: $font-size-sm;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                font-weight: $font-weight-medium;
            }
        }
    }

    .aioa_dashboard-table-body {

        .aioa_dashboard-table-tr {

            .aioa_dashboard-table-date-info {
                .aioa_dashboard-table-date-label {
                    font-weight: $font-weight-bold;
                }

                .aioa_dashboard-table-date {
                    @include media-breakpoint-up(xl) {
                        font-size: $font-size-sm;
                        font-weight: $font-weight-medium;
                    }
                }

                &.expired-date-info {
                    .aioa_dashboard-table-date-label {
                        color: $danger;
                    }
                }

                &.active-plan-info {
                    .aioa_dashboard-table-date-label {
                        color: $success;
                    }
                }

                &.trial-plan-info {
                    .aioa_dashboard-table-date-label {
                        color: $warning;
                    }
                }
            }

            &.primary-row {
                background-color: rgba($success, 0.05);

                .primary-domain {
                    display: flex;
                    margin-inline-start: 1rem;
                    font-weight: 600;

                    i {
                        width: 1.5rem;
                        height: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        background-color: $success;
                        border-radius: 100%;
                        color: $white;
                        margin-inline-end: 0.25rem;
                    }
                }
            }

            &+.aioa_dashboard-table-tr {
                border-top: 1px solid rgba($black, 0.1);
            }
        }
    }

    .aioa_dashboard-table-pagination-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 1rem;
        background-color: #F0F5F9;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        @media (min-width: 1700px) {
            padding-inline: 2rem;
        }

        .pagination {
            border: none;

            li {
                .page-link {
                    border: none;
                    background-color: transparent;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 5px;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.active {
                    .page-link {
                        background-color: $primary;
                    }
                }

                &+li {
                    margin-inline-start: 5px;
                }
            }
        }
    }
}