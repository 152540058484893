// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-page-title-wrapper {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .aioa_dashboard-page-title {

        .h6 {
            font-weight: normal;
            margin-top: 0.5rem;
        }
    }
}