// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-manage-image-alt {
    border-radius: 10px;
    background-color: $white;
    border: 1px solid rgba($black, 0.1);

    & .btn {
        &.info-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-inline-start: 0.5rem;
        }
    }

    .aioa_dashboard-image-list-filter-wrapper {
        padding: 1rem;
        width: 100%;

        @include media-breakpoint-up(xl) {
            padding: 1.5rem 2rem;
        }

        &.sticky {
            /* position: sticky;
            top: 124px;
            background-color: $body-bg; */
        }

        .aioa_dashboard-image-type-list-tab {
            width: 100%;

            ul {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                list-style: none;
                border: 1px solid rgba($primary, 0.5);
                border-radius: 5px;
                flex-wrap: wrap;

                li {
                    padding: 0.5rem;
                    background-color: rgba($primary, 0.1);
                    flex: 1 0 auto;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }

                    .btn {
                        width: 100%;
                    }

                    .btn:not(.active) {
                        background-color: transparent;
                        color: $black;
                        border-color: transparent;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }

        .aioa_dashboard-image-list-filter-block-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1.5rem;

            @include media-breakpoint-up(sm) {
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .aioa_dashboard-image-list-filter-block {
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                .form-label {
                    margin-bottom: 0;
                    flex: 0 0 auto;
                    margin-inline-end: 0.5rem;
                }

                .form-check {
                    margin: 0;
                    padding: 0;

                    .form-check-input {
                        margin: 0;
                        padding: 0;
                        width: 1.5rem;
                        height: 1.5rem;
                        border: 1px solid $primary;
                    }

                    .form-check-label {
                        margin-inline-start: 0.5rem;
                        font-weight: 500;
                    }
                }

                &+.aioa_dashboard-image-list-filter-block {
                    margin-top: 1.5rem;

                    @include media-breakpoint-up(sm) {
                        margin-inline-start: 2rem;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .aioa_dashboard-table {

        .aioa_dashboard-table-tr {
            &.error-row {
                textarea {
                    background-color: rgba($danger, 0.01);
                }
            }
        }

        .alt-text {
            .btn {
                margin-inline-start: 0.5rem;
            }
        }

        .alt-text-box-img {
            position: relative;

            .image-tag {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                width: 1.5rem;
            }

            .btn {
                width: 100%;
                height: auto;
                padding: 0;

                @include media-breakpoint-up(xl) {
                    padding: 1rem;
                }

                img {
                    width: 100%;
                    /* object-fit: cover;
                    height: 200px; */

                    @include media-breakpoint-up(xl) {
                        height: 128px;
                        object-fit: scale-down;
                    }
                }
            }
        }

        .form-check {
            margin: 0;
            padding: 0;

            .form-check-input {
                margin: 0;
                padding: 0;
                float: none;
                margin-left: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-color: $primary;
                cursor: pointer;
            }

            .form-check-label {
                margin-inline-start: 0.5rem;
                font-weight: 500;

                @include media-breakpoint-up(xl) {
                    display: none
                }
            }
        }

        .form-switch {
            padding: 0;
            margin: 0;

            .form-check-input {
                width: 2.8rem;
                height: 1.5rem;
                margin: 0;
            }
        }


        .aioa_dashboard-table-body {
            @include media-breakpoint-down(xl) {
                display: flex;
                flex-wrap: wrap;

                .aioa_dashboard-table-main-tr {
                    width: 50%;
                    border-top: none;
                    background-color: transparent;
                    padding-inline: 1rem;
                    margin-top: 2rem;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        margin-top: 0;
                    }

                    .aioa_dashboard-table-tr {
                        background-color: rgba(240, 245, 249, 0.6);
                        border-top: none;
                        padding: 2rem;
                        height: 100%;
                        position: relative;
                        border-radius: 1rem;

                        .aioa_dashboard-table-td {
                            padding: 0;

                            .aioa_dashboard-table-td-cell-value {
                                width: 100%;
                            }

                            &.tcw-250 {
                                margin-top: 1rem;
                            }

                            &.tcw-200 {
                                margin-top: 1rem;
                            }

                            &.tcw-auto {
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .aioa_dashboard-table-main-tr {

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-top: 0;
                    }

                    &:nth-child(3) {
                        margin-top: 2rem;
                    }


                }
            }

            @include media-breakpoint-down(sm) {
                .aioa_dashboard-table-main-tr {
                    width: 100%;
                    margin-top: 1.5rem;

                    &:nth-child(1) {
                        margin-top: 0;
                    }

                    &:nth-child(2) {
                        margin-top: 1.5rem;
                    }

                    &:nth-child(3) {
                        margin-top: 1.5rem;
                    }

                    .aioa_dashboard-table-tr {
                        padding: 1rem;
                    }
                }
            }
        }

    }
}