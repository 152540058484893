// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

// Custom Mixin
@import "../helpers/custom_mixin.scss";

.btn {
    &.aioa_dashboard-lang-btn {
        &.btn-outline-primary {
            display: none;

            @include media-breakpoint-up(md) {
                background-color: transparent;
                display: flex;
                align-items: center;
                padding: 0.625rem 1rem;
                border-radius: 0.625rem;
                font-size: $font-size-base;
                line-height: normal;
                color: $black;
                font-weight: $font-weight-normal;
                border: 1px solid $primary;
            }

            .aioa_dashboard-lang-flag {
                width: auto;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                color: $white;
                border-radius: 0.3rem;
                line-height: 1;
                padding-inline: 0.5rem;
                font-weight: 500;
                text-transform: uppercase;

                @media (min-width: 1450px) {
                    margin-inline-end: 1rem;
                }

                img {
                    object-fit: cover;
                    width: 46px;
                    height: 30px;
                }
            }

            .aioa_dashboard-lang-name {
                display: none;

                @media (min-width: 1450px) {
                    display: inline-block;
                }
            }

            &:hover,
            &:focus {
                background-color: rgba($primary, 0.1);
                color: $black;
            }
        }
    }
}

.aioa_dashboard-language-list {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollBars(8px, $primary);

    .aioa_dashboard-lang-btn {
        background-color: rgba($primary, 0.1);
        display: flex;
        align-items: center;
        padding: 0.625rem 1rem;
        border-radius: 0.625rem;
        font-size: $font-size-base;
        line-height: normal;
        color: $black;
        font-weight: $font-weight-normal;
        border: 1px solid $gray-300;
        width: 100%;
        height: 100%;

        .aioa_dashboard-lang-flag {
            width: auto;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-inline-end: 1rem;
            border-radius: 100%;
            overflow: hidden;
            padding-inline: 0.5rem;
            background-color: $primary;
            color: $white;
            border-radius: 0.3rem;
            line-height: 1;
            flex: 0 0 auto;
            font-weight: 500;
            text-transform: uppercase;

            img {
                object-fit: cover;
                width: 2rem;
                height: 2rem;
            }
        }

        &:hover,
        &:focus,
        &.active {
            background-color: rgba($primary, 1);
            color: $white;

            .aioa_dashboard-lang-flag {
                background-color: $white;
                color: $primary;
            }
        }
    }
}