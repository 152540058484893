// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-plan-status-alert {

    display: flex;
    align-items: center;

    &.alert-danger {
        --bs-alert-color: var(--bs-white);
        --bs-alert-bg: var(--bs-danger);
        --bs-alert-border-color: var(--bs-danger);
        --bs-alert-link-color: var(--bs-white);
        border-radius: 10px;
    }

    .aioa_dashboard-plan-status-alert-text {
        flex: 1 1 auto;
        font-size: 18px;
        line-height: 1.5;
    }

    .aioa_dashboard-plan-status-alert-btn {
        flex: 0 0 auto;
        margin-inline-start: 1.5rem;
    }
}