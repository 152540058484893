// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-copy-widget-code {

    &:not(.aioa_dashboard-widget-code-modal) {
        background-color: $white;
        border-radius: 10px;
        border: 1px solid rgba($black, 0.1);
    }

    .aioa_dashboard-widget-code {
        background-color: rgba($black, 1);
        border-radius: 0.5rem;
        overflow: hidden;
        position: relative;

        .aioa_dashboard-widget-code-copy-btn {
            position: absolute;
            right: 0;
            top: 0;

            &>.btn {
                border-radius: 0;
            }
        }

        pre {
            margin: 0;
            color: rgba($white, 0.9);
            padding: 1rem 5rem 1rem 1rem;
            white-space: break-spaces;
            font-size: 1rem;
            word-break: break-all;
        }
    }

    .aioa_dashboard-widget-code-toggle-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        .aioa_dashboard-widget-code-toggle {
            display: flex;
            align-items: center;

            .form-check {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;

                .form-check-input {
                    margin: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-color: $primary;
                }

                .form-check-label {
                    font-size: $font-size-base;
                    font-weight: $font-weight-medium;
                    margin-inline-start: 0.5rem;
                }
            }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-start: 0.5rem;
                border: none !important;
                padding: 0.1rem;
                background-color: rgba($primary, 0.1);

                &:hover,
                &:focus {
                    background-color: $primary;
                }
            }
        }
    }
}