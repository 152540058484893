// Icon Mixin
@mixin SVGIcon($img) {
    -webkit-mask-size: contain;
    mask-size: contain;
    mask : url(../../img/#{$img}) no-repeat center center / 100% auto;
    -webkit-mask : url(../../img/#{$img}) no-repeat center center / 100% auto;
    -moz-mask : url(../../img/#{$img}) no-repeat center center / 100% auto;
}

// Icon Mixin
@mixin AIOASVGIcon($img) {
    -webkit-mask-size: contain;
    mask-size: contain;
    mask : url(../../img/widget-icon/#{$img}) no-repeat center center / 100% auto;
    -webkit-mask : url(../../img/widget-icon/#{$img}) no-repeat center center / 100% auto;
    -moz-mask : url(../../img/widget-icon/#{$img}) no-repeat center center / 100% auto;
}

// CUSTOM SCROLLBAR
@mixin customScrollBars($size, $foreground-color, $background-color: mix($foreground-color, white,  30%)) {
	
	/* ===== Scrollbar CSS ===== */
  /* Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $foreground-color $background-color;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width:  $size;
	  height: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $foreground-color;
    border-radius: 50px;
  }
}