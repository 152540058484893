// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-settings-screen {
    
}

.manage-cards {
    margin-top: 1.5rem;

    .card {
        --bs-card-spacer-y: 1.5rem;
        --bs-card-spacer-x: 1.5rem;
        --bs-card-cap-padding-y: 1.5rem;
        --bs-card-cap-padding-x: 1.5rem;
        //--bs-card-cap-bg: rgba(var(--bs-primary-bg-subtle), 1);

        .card-header {
            //background-color: rgba($primary, 0.2);
            background-color: var(--bs-primary-bg-subtle);
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                color: var(--bs-primary-text-emphasis);
            }
        }

        .card-body {
            .credit-card-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 2px solid #f0f0f0;
                box-shadow: 0 0 12px rgba($black, 0.06);
                padding: 1rem;
                border-radius: 0.5rem;
                width: 100%;
                margin-top: 1rem;
                flex-direction: column;

                @media (min-width: 576px) {
                    flex-direction: row;
                }

                .credit-card-details {
                    display: flex;
                    align-items: center;

                    @media (max-width: 575px) {
                        flex-grow: 1;
                        width: 100%;
                        margin-bottom: 0.5rem;
                        padding-bottom: 0.5rem;
                        border-bottom: 1px solid #f8f8f8;
                    }

                    .credit-card-img-box {
                        width: 5rem;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 1rem;
                        margin-right: 1rem;

                        img {
                            width: 100%;
                            height: auto;
                            max-width: 100%;
                        }
                    }

                    .credit-card-detail-box {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .action-btns {
                    display: flex;
                    align-items: center;

                    @media (max-width: 575px) {
                        flex-grow: 1;
                        width: 100%;
                        justify-content: space-between;
                    }

                    .btn {
                        &.btn-danger {
                            width: 2.5rem;
                            height: 2.5rem;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            flex: 0 0 auto;
                        }

                        &.btn-success {
                            width: 2.5rem;
                            height: 2.5rem;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            pointer-events: none;
                            flex: 0 0 auto;
                        }

                        &+.btn {
                            margin-left: 1rem;
                        }
                    }
                }

                &.active {
                    border-color: $success;
                }

                &.placeholder-card-box {
                    .placeholder-glow {
                        width: 100%;
                        height: 100%;

                        .placeholder {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .credit-card-details {
                        flex-grow: 1;

                        .credit-card-img-box {
                            .placeholder-glow {
                                height: 48px;
                            }
                        }

                        .credit-card-detail-box {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.card-input-group {
    .form-control {
        padding: 0 1rem;
        line-height: normal;
        border: 1px solid #d2d6da;
        height: 40px;
    }

    .card-input-elements {
        .card-input {
            display: flex;
            align-items: center;
            height: 40px;
            border: 1px solid #d2d6da;
            padding: 0.625rem 0.75rem;
            border-radius: 0.375rem;

            .StripeElement {
                width: 100%;
            }
        }
    }
}