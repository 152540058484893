// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-pdf-remediation {
    border-radius: 10px;
    background-color: $white;
    border: 1px solid rgba($black, 0.1);

    & .btn {
        &.info-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-inline-start: 0.5rem;
        }
    }

    .aioa_dashboard-pdf-remediation-filter-wrapper {
        padding: 1.5rem 2rem;
        width: 100%;

        &.sticky {
            /* position: sticky;
            top: 124px;
            background-color: $body-bg; */
        }

        .aioa_dashboard-pdf-remediation-type-list-tab {
            width: 100%;

            ul {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                list-style: none;
                border: 1px solid rgba($primary, 0.5);
                border-radius: 5px;

                li {
                    padding: 0.5rem;
                    background-color: rgba($primary, 0.1);
                    flex: 1 0 auto;

                    .btn {
                        width: 100%;
                    }

                    .btn:not(.active) {
                        background-color: transparent;
                        color: $black;
                        border-color: transparent;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }

        .aioa_dashboard-pdf-remediation-filter-block-wrapper {
            width: 100%;
            margin-top: 1.5rem;

            @media screen and (min-width:1800px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .aioa_dashboard-pdf-remediation-filter-block-list {
                display: flex;
                align-items: center;
                margin-top: 1.5rem;

                @media screen and (min-width:1800px) {
                    margin-top: 0;
                    margin-inline-start: 1rem;
                }
            }

            .aioa_dashboard-pdf-remediation-filter-block {
                display: flex;
                align-items: center;

                .form-label {
                    margin-bottom: 0;
                    flex: 0 0 auto;
                    margin-inline-end: 0.5rem;
                }

                &+.aioa_dashboard-pdf-remediation-filter-block {
                    margin-inline-start: 2rem;
                }
            }
        }
    }

    .aioa_dashboard-table {

        .aioa_dashboard-table-tr {
            &.error-row {
                textarea {
                    background-color: rgba($danger, 0.01);
                }
            }
        }

        .alt-text-box-img {


            .btn {
                width: 100%;
                height: auto;
                //padding: 1rem;

                img {
                    height: 128px;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }

        .form-check {
            .form-check-input {
                margin: 0;
                padding: 0;
                float: none;
                margin-left: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-color: $primary;
                cursor: pointer;
            }
        }

        .form-switch {
            padding: 0;
            margin: 0;

            .form-check-input {
                width: 2.8rem;
                height: 1.5rem;
                margin: 0;
            }
        }
    }
}