// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

// custom mixin
@import "../helpers/custom_mixin.scss";

.aioa_dashboard-domain-dropdown-wrapper {
    position: relative;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
        margin-inline-end: 1.25rem;
        margin-bottom: 0;
    }

    .aioa_dashboard-domain-dropdown-btn {
        background-color: $white;
        display: flex;
        align-items: center;
        padding: 0.625rem 2rem 0.625rem 0.625rem;
        border-radius: 0.625rem;
        max-width: 100%;
        font-size: $font-size-base;
        line-height: normal;
        color: $black;
        font-weight: $font-weight-medium;
        position: relative;
        width: calc(100vw - 3rem);

        @include media-breakpoint-up(sm) {
            font-size: $font-size-lg;
        }

        @include media-breakpoint-up(md) {
            width: 22rem;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.625rem;
            width: 13px;
            height: 10px;
            background-color: $black;
            @include SVGIcon('icon-dropdown.svg');

            @include media-breakpoint-up(sm) {
                width: 17px;
                height: 10px;
            }
        }

        .aioa_dashboard-domain-dropdown-favicon {
            width: 2rem;
            height: 2rem;
            border-radius: 5px;
            background-color: rgba($primary, 0.05);
            margin-inline-end: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;

            @include media-breakpoint-up(sm) {
                width: 2.5rem;
                height: 2.5rem;
            }

            &.no-icon {
                background-color: $black;
                color: $white;
            }
        }

        .aioa_dashboard-domain-dropdown-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.single-site {
            pointer-events: none;

            &::after {
                display: none;
            }
        }

        &:hover,
        &:focus {
            background-color: rgba($primary, 0.1);
        }

    }

    .aioa_dashboard-domain-dropdown-list-wrapper {
        position: absolute;
        top: 100%;
        background-color: $white;
        padding: 1.5rem 0 0;
        border-radius: 1rem;
        margin-top: 0.5rem;
        border: 1px solid rgba($black, 0.1);
        overflow: hidden;
        min-width: 100%;

        .aioa_dashboard-domain-dropdown-list {
            max-height: 500px;
            overflow-y: auto;
            overflow-x: hidden;
            padding-inline: 1.5rem;
            @include customScrollBars(8px, $primary);

            .aioa_dashboard-domain-dropdown-btn-box {
                .aioa_dashboard-domain-dropdown-btn {
                    width: auto;

                    &::after {
                        display: none;
                    }

                    &.active {
                        background-color: rgba($primary, 0.1);
                        pointer-events: none;
                    }
                }

                &+.aioa_dashboard-domain-dropdown-btn-box {
                    border-top: 1px solid rgba($black, 0.1);
                    margin-top: 0.5rem;
                    padding-top: 0.5rem;
                }
            }

        }

        .aioa_dashboard-view-all-domain {

            a {
                text-align: center;
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                background-color: #F7FAFC;
                font-size: $font-size-base;
                line-height: normal;
                font-weight: $font-weight-medium;
                padding: 0.8rem;

                &:hover,
                &:focus {
                    background-color: rgba($primary, 0.1);
                }
            }
        }
    }
}