
// // // Configuration
// @import "../bootstrap/functions";
// @import "../bootstrap/variables";
// @import "../bootstrap/variables-dark";
// @import "../bootstrap/maps";
// @import "../bootstrap/mixins";
// @import "../bootstrap/utilities";


// .date-range-wrapper {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;

//   .date-range-picker {
//     width: 400px
//   }

//   .dropdown-toggle.custom-dropdown {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     padding: 0.5rem 1rem;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     font-size: 1rem;
//     color: #333;
//     cursor: pointer;
//     position: relative;

//     /* Remove the default dropdown arrow */
//     appearance: none;
//     -webkit-appearance: none; /* For Safari */
//     -moz-appearance: none; /* For Firefox */

//     /* Remove any default arrow styling */
//     &::after {
//       content: none; /* Prevent default arrow styling */
//     }

//     &:focus {
//       outline: none;
//       border-color: #007bff;
//       box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
//     }

//     .dropdown-arrow {
//       margin-left: 10px;
//       pointer-events: none; /* Prevent interference with clicks */
//     }
//   }

//   .dropdown-menu {
//     padding: 1rem;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     width: 100%;

//     .predefined-ranges {
//       display: flex;
//       flex-direction: column;
//       gap: 0.5rem;

//       .range-item {
//         padding: 0.5rem;
//         font-size: 0.9rem;
//         color: #333;
//         cursor: pointer;
//         border: 1px solid transparent;
//         border-radius: 4px;

//         &:hover {
//           background-color: #f8f9fa;
//           border-color: #ddd;
//         }

//         &.selected {
//           background-color: #007bff;
//           color: #fff;
//         }

//         .custom-range-container {
//           display: flex;
//           flex-direction: column;
//           gap: 0.5rem;
//           margin-top: 0.5rem;

//           .custom-range-fields {
//             display: flex;
//             flex-direction: column;
//             gap: 0.5rem;

//             label {
//               font-size: 0.8rem;
//               color: #6c757d;
//             }

//             input[type="date"] {
//               padding: 0.5rem;
//               border: 1px solid #ddd;
//               border-radius: 4px;
//               font-size: 0.9rem;
//               width: 100%;

//               &:focus {
//                 outline: none;
//                 border-color: #007bff;
//                 box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
//               }
//             }
//           }

//           .button-group {
//             display: flex;
//             justify-content: flex-end;

//             .apply-btn {
//               padding: 0.5rem 1rem;
//               background-color: #007bff;
//               color: #fff;
//               border: none;
//               border-radius: 4px;
//               font-size: 0.9rem;
//               cursor: pointer;

//               &:hover {
//                 background-color: #0056b3;
//               }
//             }
//           }
//         }
//       }
//     }
//   }

//   .clear-btn {
//     align-self: flex-end;
//     padding: 0.5rem 1rem;
//     background-color: #dc3545;
//     color: #fff;
//     border: none;
//     border-radius: 4px;
//     font-size: 0.9rem;
//     cursor: pointer;

//     &:hover {
//       background-color: #c82333;
//     }
//   }
// }

// Configuration
// @import "../bootstrap/functions";
// @import "../bootstrap/variables";
// @import "../bootstrap/variables-dark";
// @import "../bootstrap/maps";
// @import "../bootstrap/mixins";
// @import "../bootstrap/utilities";

// .date-range-wrapper {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;

//   .date-range-container {
//     display: flex;
//     align-items: center; /* Vertically align dropdown and reset button */
//     gap: 0; /* Ensure reset button sticks to input */
//   }

//   .date-range-picker {
//     width: 300px; /* Reduced width of the dropdown */
//   }

//   .dropdown-toggle.custom-dropdown {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     padding: 0.5rem 1rem;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     cursor: pointer;
//     position: relative;
//     font-size:16px;
//     color: #495057;
//     letter-spacing: 0.2px; /* Add word spacing to match design */
//     font-weight: 400; /* Match font weight if needed */

//     /* Remove the default dropdown arrow */
//     appearance: none;
//     -webkit-appearance: none; /* For Safari */
//     -moz-appearance: none; /* For Firefox */

//     /* Remove any default arrow styling */
//     &::after {
//       content: none; /* Prevent default arrow styling */
//     }

//     &:focus {
//       outline: none;
//       border-color:#6a5acd;
//       box-shadow: 0 0 6px rgba(106, 90, 205, 0.6);
//     }

//     .dropdown-arrow {
//       margin-left: 10px;
//       pointer-events: none; /* Prevent interference with clicks */
//     }
//   }

//   .clear-btn {
//     margin-left: -1px; /* Ensure reset button sticks to dropdown */
//     padding: 0.5rem 1rem;
//     background-color: #dc3545;
//     color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 0 4px 4px 0; /* Rounded corners only on the right */
//     font-size: 0.9rem;
//     cursor: pointer;
//     height: calc(100% - 2px); /* Match height of the dropdown */

//     &:hover {
//       background-color: #c82333;
//     }

//     &:focus {
//       outline: none;
//       box-shadow: 0 0 3px rgba(220, 53, 69, 0.5);
//     }
//   }

//   .dropdown-menu {
//     padding: 1rem;
//     background-color: #fff; /* Dropdown menu background */
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     width: 100%;

//     .predefined-ranges {
//       display: flex;
//       flex-direction: column;
//       gap: 0.5rem;

//       .range-item {
//         padding: 0.5rem;
//         font-size: 0.9rem;
//         color: #242323;
//         cursor: pointer;
//         border: 1px solid transparent;
//         border-radius: 4px;

//         &:hover {
//           background-color:#d0bae3;
//           border-color:#d0bae3;
//         }

//         &.selected {
//           background-color:#d0bae3;
//           color: #343434;
//         }

//         .custom-range-container {
//           background-color: #fff; /* Set background to white */
//           padding: 1rem;
//           border: 1px solid #ddd;
//           border-radius: 4px;
//           display: flex;
//           flex-direction: column;
//           gap: 1rem;

//           .custom-range-fields {
//             display: flex;
//             flex-direction: column;
//             gap: 0.5rem;

//             label {
//               font-size: 0.8rem;
//               color: #6c757d;
//             }

//             input[type="date"] {
//               padding: 0.5rem;
//               border: 1px solid #ddd;
//               border-radius: 4px;
//               font-size: 0.9rem;
//               width: 100%;

//               &:focus {
//                 outline: none;
//                 border-color: $primary;
//                 box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
//               }
//             }
//           }

//           .button-group {
//             display: flex;
//             justify-content: left; /* Align the Apply button to the right */
//             margin-top: 1rem;

//             .apply-btn {
//               padding: 0.5rem 1rem;
//               background-color: #350069;
//               color: #fff;
//               border: none;
//               border-radius: 4px;
//               font-size: 0.9rem;
//               cursor: pointer;

//               &:hover {
//                 background-color: #350069;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }


// @import "../bootstrap/functions";
// @import "../bootstrap/variables";
// @import "../bootstrap/variables-dark";
// @import "../bootstrap/maps";
// @import "../bootstrap/mixins";
// @import "../bootstrap/utilities";

// .date-range-wrapper {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;

//   .date-range-container {
//     display: flex;
//     align-items: center; /* Vertically align dropdown and reset button */
//     gap: 0; /* Ensure reset button sticks to input */
//   }

//   .date-range-picker {
//     width: 300px; /* Reduced width of the dropdown */
//   }

//   .dropdown-toggle.custom-dropdown {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     padding: 0.5rem 1rem;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     cursor: pointer;
//     position: relative;
//     font-size: 16px; /* Match font size */
//     color: #495057; /* Font color */
//     letter-spacing: 0.2px; /* Add word spacing to match design */
//     font-weight: 400; /* Match font weight */

//     /* Remove the default dropdown arrow */
//     appearance: none;
//     -webkit-appearance: none; /* For Safari */
//     -moz-appearance: none; /* For Firefox */

//     &::after {
//       content: none; /* Prevent default arrow styling */
//     }

//     &:focus {
//       outline: none;
//       border-color: #6a5acd;
//       box-shadow: 0 0 6px rgba(106, 90, 205, 0.6);
//     }

//     .dropdown-arrow {
//       margin-left: 10px;
//       pointer-events: none; /* Prevent interference with clicks */
//     }
//   }

//   .clear-btn {
//     margin-left: -1px; /* Ensure reset button sticks to dropdown */
//     padding: 0.5rem 1rem;
//     background-color: #dc3545;
//     color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 0 4px 4px 0; /* Rounded corners only on the right */
//     font-size: 0.9rem;
//     cursor: pointer;
//     height: calc(100% - 2px); /* Match height of the dropdown */

//     &:hover {
//       background-color: #c82333;
//     }

//     &:focus {
//       outline: none;
//       box-shadow: 0 0 3px rgba(220, 53, 69, 0.5);
//     }
//   }

//   .dropdown-menu {
//     padding: 1rem;
//     background-color: #fff; /* Dropdown menu background */
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     width: 100%;

//     .predefined-ranges {
//       display: flex;
//       flex-direction: column;
//       gap: 0.5rem;

//       .range-item {
//         padding: 0.5rem;
//         font-size: 0.9rem;
//         color: #242323;
//         cursor: pointer;
//         border: 1px solid transparent;
//         border-radius: 4px;

//         &:hover {
//           background-color: #d0bae3;
//           border-color: #d0bae3;
//         }

//         &.selected {
//           background-color: #d0bae3;
//           color: #343434;
//         }

//         .custom-range-container {
//           background-color: #fff; /* Set background to white */
//           padding: 1rem;
//           border: 1px solid #ddd;
//           border-radius: 4px;
//           display: flex;
//           flex-direction: column;
//           gap: 1rem;

//           .custom-range-fields {
//             display: flex;
//             flex-direction: column;
//             gap: 0.5rem;

//             label {
//               font-size: 0.8rem;
//               color: #6c757d;
//             }

//             input[type="date"] {
//               padding: 0.5rem;
//               border: 1px solid #ddd;
//               border-radius: 4px;
//               font-size: 0.9rem;
//               width: 100%;

//               &:focus {
//                 outline: none;
//                 border-color: #6a5acd;
//                 box-shadow: 0 0 3px rgba(106, 90, 205, 0.5);
//               }
//             }
//           }

//           .button-group {
//             display: flex;
//             justify-content: flex-start; /* Align the Apply button to the left */
//             margin-top: 1rem;

//             .apply-btn {
//               padding: 0.5rem 1rem;
//               background-color: #350069;
//               color: #fff;
//               border: none;
//               border-radius: 4px;
//               font-size: 0.9rem;
//               cursor: pointer;

//               &:hover {
//                 background-color: #4b0082;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }


@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.date-range-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .date-range-container {
    display: flex;
    align-items: center; /* Vertically align dropdown and reset button */
    gap: 0; /* Ensure reset button sticks to input */
  }

  .date-range-picker {
    width: 300px; /* Reduced width of the dropdown */
  }

  .dropdown-toggle.custom-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color:var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: var(--bs-box-shadow-inset);
    border-radius: 4px;
    font-family: inherit;
    cursor: pointer;
    position: relative;
    font-size: 16px; /* Match font size */
    color: #495057; /* Font color */
    letter-spacing: 0.2px; /* Add word spacing to match design */
    font-weight: 400; /* Match font weight */
    transition: border-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */

    /* Remove the default dropdown arrow */
    appearance: none;
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */

    &::after {
      content: none; /* Prevent default arrow styling */
    }

    &:focus {
      outline: none; /* Removes default outline */
      border-color: #6a5acd; /* Highlight border color on focus */
      box-shadow: 0 0 6px 2px rgba(106, 90, 205, 0.5); /* Purple glow effect */
  
    }

    .dropdown-arrow {
      margin-left: 10px;
      pointer-events: none; /* Prevent interference with clicks */
    }
  }

  .clear-btn {
    margin-left: -1px; /* Ensure reset button sticks to dropdown */
    padding: 0.5rem 1rem;
    background-color: #dc3545;
    color: #fff;
    border: 1px solid #ddd;
    border-radius: 0 4px 4px 0; /* Rounded corners only on the right */
    font-size: 0.9rem;
    cursor: pointer;
    height: calc(100% - 2px); /* Match height of the dropdown */

    &:hover {
      background-color: #c82333;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 3px rgba(220, 53, 69, 0.5);
    }
  }

  .dropdown-menu {
    padding: 1rem;
    background-color: #fff; /* Dropdown menu background */
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;

    .predefined-ranges {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .range-item {
        padding: 0.5rem;
        font-size: 0.9rem;
        color: #242323;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 4px;

        &:hover {
          background-color: #d0bae3;
          border-color: #d0bae3;
        }

        &.selected {
          background-color: #d0bae3;
          color: #343434;
        }
      }
    }

    .custom-range-container {
      border: 1px solid #ddd; /* Add a light border */
      border-radius: 4px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .custom-range-title {
        font-size: 0.875rem; /* Small font for the title */
        font-weight: 500;
        color: #495057;
        margin-bottom: 0.5rem;
      }

      .custom-range-fields {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        label {
          font-size: 0.8rem; /* Smaller label font */
          color: #6c757d;
        }

        input[type="date"] {
          padding: 0.5rem;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 0.9rem;
          width: 100%;

          &:focus {
            outline: none;
            border-color: #6a5acd;
            box-shadow: 0 0 3px rgba(106, 90, 205, 0.5);
          }
        }
      }

      .button-group {
        display: flex;
        justify-content: flex-start; /* Align the Apply button to the left */
        margin-top: 0.5rem;

        .apply-btn {
          padding: 0.5rem 1rem;
          background-color: #350069;
          color: #fff;
          border: none;
          border-radius: 4px;
          font-size: 0.875rem;
          cursor: pointer;

          &:hover {
            background-color: #4b0082;
          }
        }
      }
    }
  }
}
