// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

@import "../helpers/custom_mixin.scss";

.menu-list {
    &.scroll {
        height: calc(720px);
        overflow-y: auto;
        @include customScrollBars(8px, $primary);
        margin-right: -8px;
    }

    .menu-box {
        display: flex;
        align-items: center;
        padding: 1rem;

        &.hovering {
            background-color: $gray-200;
        }

        &+.menu-box {
            margin-top: 0.5rem;
        }

        &:last-child {
            margin-bottom: 3rem;
        }


        .drag-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            .material-icons {
                font-size: 1.5rem;
            }
        }

        .menu-name {
            flex: 1 1 auto;

            &.icon-with-name {
                display: flex;
                align-items: center;

                .icon {
                    width: 25px;
                    height: 25px;
                    min-width: 25px;
                    margin-inline-end: 10px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background-color: rgba($primary, 0.1);
                    white-space: nowrap;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 12px;

                    /* img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    } */
                }
            }
        }

        .action-part {
            display: flex;
            align-items: center;
            justify-content: center;

            .form-switch {
                padding: 0;
                display: flex;
            }

            .form-check-input {
                margin: 0;
            }
        }

        .placeholder-glow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .placeholder {
                width: 100%;
            }

            .drag-icon {
                width: 1.5rem;
                height: 1.5rem;
            }

            .action-part {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}