// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";


.aioa_dashboard-custom-branding-service-wrapper {
    margin-top: 1.5rem;

    .aioa_dashboard-custom-branding-service-logo-upload-wrapper {
        .card {
            height: 100%;
            background-color: $white;
        }
    }

    .aioa_dashboard-custom-branding-service-domain-list-wrapper {
        margin-top: 1.5rem;

        .card {
            height: 100%;
            background-color: $white;
            --bs-card-cap-padding-y: 1.5rem;
            --bs-card-cap-padding-x: 2rem;
            --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
            --bs-card-cap-bg: var(--bs-primary-bg-subtle);
            --bs-card-bg: var(--bs-white);

            .card-header {
                padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
                margin-bottom: 0;
                color: var(--bs-card-cap-color);
                background-color: var(--bs-card-cap-bg);
                border-bottom: none;
            }

            .card-body {
                padding: 0;

                .aioa_dashboard-custom-branding-service-domain-list-table {
                    height: 100%;

                    .aioa_dashboard-table {
                        .aioa_dashboard-table-tr {
                            .aioa_dashboard-table-td {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                .domain-name {
                                    display: flex;

                                    span {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }

                        .aioa_dashboard-table-pagination-main {
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;

                            .record-count {
                                /* margin-bottom: 1rem; */
                            }
                        }
                    }

                    .form-check {
                        .form-check-input {
                            width: 1.5rem;
                            height: 1.5rem;
                            margin: 0;
                        }
                    }
                }
            }
        }


        .aioa_dashboard-order-list-filter-wrapper {
            padding: 1.5rem 2rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;

            &.sticky {
                /* position: sticky;
                top: 124px;
                background-color: $body-bg; */
            }

            .aioa_dashboard-order-list-filter-block {
                display: flex;
                align-items: center;
                flex: 1 0 auto;
                width: 100%;

                .form-label {
                    margin-bottom: 0;
                    flex: 0 0 auto;
                    margin-inline-end: 0.5rem;
                }

                &+.aioa_dashboard-order-list-filter-block {
                    margin-top: 1rem;
                }
            }
        }
    }

    .aioa_dashboard-custom-branding-service-domain-list-save-btn {
        position: sticky;
        bottom: 0;
        padding-inline: 2rem;
        background-color: $white;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.logo-preview-main {
    display: flex;

    .logo-preview {
        padding: 1rem;
        box-shadow: 0 0 12px rgba($black, 0.06);
        border: 1px solid rgba($black, 0.06);
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        margin-right: 1.5rem;

        img {
            width: 110px;
            height: 45px;
            max-width: 100%;
            line-height: 0;
        }
    }

    .logo-upload {
        display: flex;
        align-items: center;

        .logo-upload-inner {
            display: flex;
            align-items: center;

            .spinner-border {
                margin-left: 1rem;
                flex: 0 0 auto;
            }

            .logo-upload-input {
                position: relative;
                margin-right: 1rem;
                flex: 0 0 auto;

                .form-label {
                    margin: 0;
                    color: $white;
                }

                .input-group {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                }

                &.loading {
                    pointer-events: none;
                    opacity: 0.8;
                }

            }

            .file-url {
                word-break: break-all;
            }
        }
    }
}