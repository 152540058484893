// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

@import "../helpers/custom_mixin.scss";

.aioa_dashboard-widget-settings {

    .aioa_dashboard-widget-settings-wrapper {

        .accordion {
            .accordion-item {

                /* border-radius: 10px;
            overflow: hidden; */

                .accordion-header {
                    .accordion-button {
                        font-size: 1.25rem;
                        font-weight: $font-weight-medium;
                    }
                }

                &+.accordion-item {
                    margin-top: 2rem;
                }
            }
        }

        .aioa_dashboard-widget-setting-icon-box {
            width: 100%;
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 8px rgba($black, 0.08);
            border: 2px solid rgba($black, 0.08);
            position: relative;
            //cursor: pointer;
            border-radius: 0.5rem;

            .form-check {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;


                .form-check-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    box-shadow: none;
                    outline: none;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    border: none;
                    cursor: pointer;
                    background-image: none;
                    border-radius: 0;
                    z-index: 1;
                }
            }

            .form-check-label {
                margin-inline-start: 0;
                padding: 0.5rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .aioa_dashboard-widget-icon-box {
                width: 4rem;
                height: 4rem;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--widget--layout-selected-color, $primary);

                &::after {
                    content: "";
                    width: 3rem;
                    height: 3rem;
                    background-color: var(--widget--layout-selected-text-color, $white);
                }

                &.aioaIconType1 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-1.svg');
                    }
                }

                &.aioaIconType2 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-2.svg');
                    }
                }

                &.aioaIconType3 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-3.svg');
                    }
                }

                &.aioaIconType4 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-4.svg');
                    }
                }

                &.aioaIconType5 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-5.svg');
                    }
                }

                &.aioaIconType6 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-6.svg');
                    }
                }

                &.aioaIconType7 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-7.svg');
                    }
                }

                &.aioaIconType8 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-8.svg');
                    }
                }

                &.aioaIconType9 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-9.svg');
                    }
                }

                &.aioaIconType10 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-10.svg');
                    }
                }

                &.aioaIconType11 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-11.svg');
                    }
                }

                &.aioaIconType12 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-12.svg');
                    }
                }

                &.aioaIconType13 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-13.svg');
                    }
                }

                &.aioaIconType14 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-14.svg');
                    }
                }

                &.aioaIconType15 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-15.svg');
                    }
                }

                &.aioaIconType16 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-16.svg');
                    }
                }

                &.aioaIconType17 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-17.svg');
                    }
                }

                &.aioaIconType18 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-18.svg');
                    }
                }

                &.aioaIconType19 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-19.svg');
                    }
                }

                &.aioaIconType20 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-20.svg');
                    }
                }

                &.aioaIconType21 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-21.svg');
                    }
                }

                &.aioaIconType22 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-22.svg');
                    }
                }

                &.aioaIconType23 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-23.svg');
                    }
                }

                &.aioaIconType24 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-24.svg');
                    }
                }

                &.aioaIconType25 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-25.svg');
                    }
                }

                &.aioaIconType26 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-26.svg');
                    }
                }

                &.aioaIconType27 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-27.svg');
                    }
                }

                &.aioaIconType28 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-28.svg');
                    }
                }

                &.aioaIconType29 {
                    &::after {
                        @include AIOASVGIcon('aioa-icon-type-29.svg');
                    }
                }
            }

            .aioa_dashboard-widget-setting-icon-active-icon {
                position: absolute;
                top: 0;
                right: 0;
                background-color: $success;
                width: 1.2rem;
                height: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                visibility: hidden;
                opacity: 0;

                i {
                    color: $white;
                    font-size: 1rem;
                }
            }

            &.flat-logo {
                .aioa_dashboard-widget-icon-box {
                    width: 100%;
                    height: auto;
                    border-radius: 0.3rem;
                    padding: 0.3rem;

                    &::after {
                        content: "";
                        width: 100%;
                        height: 3rem;
                        background-color: var(--widget--layout-selected-text-color, $white);
                    }

                    svg {
                        width: 100%;
                        height: auto;

                        path {
                            fill: var(--widget--layout-selected-text-color, $white);
                        }
                    }
                }
            }

            &:hover,
            &:focus {
                border-color: $primary;
            }

            &.active {
                border-color: $success;

                .aioa_dashboard-widget-setting-icon-active-icon {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .aioa_dashboard-widget-settings-btns {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-inline: -0.75rem;

            .btn {
                margin: 0.75rem;
                background-color: var(--widget--layout-selected-color, $primary);
                border-color: var(--widget--layout-selected-color, $primary);
                color: var(--widget--layout-selected-text-color, $white);

                &:not(.active):hover,
                &:not(.active):focus {
                    background-color: var(--widget--layout-selected-color-dark, $primary);
                    border-color: var(--widget--layout-selected-color-dark, $primary);
                    color: var(--widget--layout-selected-text-color, $white);
                }

                &.active {
                    background-color: $success;
                    border-color: $success;
                    color: $white;
                }
            }
        }

        .aioa_dashboard-widget-settings-screen-btns {
            position: sticky;
            bottom: 0;
            background-color: $body-bg;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;

            .btn {
                &+.btn {
                    margin-inline-start: 1rem;
                }
            }
        }

        .aioa_dashboard-widget-custom-settings {

            &.border-top {
                margin-top: 1.5rem;
                padding-top: 1.5rem;
            }

            &.border-bottom {
                margin-bottom: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }

    }

}

.aioa_dashboard-custom-language-select {
    .lang-select__control {
        background-color: transparent;
        min-height: calc(1.5em + 1rem + calc(var(--bs-border-width)* 2));
        border-radius: var(--bs-border-radius-lg);

        &.lang-select__control--is-focused {
            /*  border-color: $primary;
            box-shadow: 0 0 0 1px $primary; */
        }
    }

    .lang-select__value-container {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
    }

    .lang-select__input-container {
        margin: 0;
    }

    .lang-select__menu {
        font-size: 1.25rem;
    }

}

.voice-selection-table-wrapper {
    max-height: 500px;
    overflow: auto;

    .btn.btn-link {
        padding: 0;
        border-radius: 100%;
        span{
            display: block;
        }
    }
    .form-check-input{
        border-color: rgba($black, 0.8);
    }
}