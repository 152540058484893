// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-manual-audit-block {
    position: sticky;
    top: 8rem;

    .aioa_dashboard-manual-audit-block-inner {
        padding: 1.5rem;
        background-color: rgba($primary, 0.1);
        border: 1px solid rgba($primary, 0.8);
        border-radius: 0.625rem;
        text-align: center;

        .aioa_dashboard-manual-audit-block-title {
            color: $primary;
            margin-bottom: 1rem;
            line-height: 28px;
        }

        .aioa_dashboard-manual-audit-block-text {
            margin-bottom: 1.5rem;
        }

        .aioa_dashboard-manual-audit-block-btn {
            .btn {
                font-weight: $font-weight-bold;
            }
        }

        & + .aioa_dashboard-manual-audit-block-inner {
            margin-top: 1.5rem;
        }
    }
}