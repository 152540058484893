// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-order-list {
    border-radius: 10px;
    background-color: $white;
    border: 1px solid rgba($black, 0.1);

    .aioa_dashboard-order-list-filter-wrapper {
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            padding: 1.5rem 2rem;
            align-items: center;
            justify-content: flex-end;
            flex-direction: row;
        }

        &.sticky {
            /* position: sticky;
            top: 124px;
            background-color: $body-bg; */
        }

        .aioa_dashboard-order-list-filter-block {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                align-items: center;
            }

            .form-label {
                margin-bottom: 0.5rem;
                flex: 0 0 auto;

                @include media-breakpoint-up(md) {
                    margin-inline-end: 0.5rem;
                    margin-bottom: 0;
                }
            }

            &+.aioa_dashboard-order-list-filter-block {
                margin-top: 1.5rem;

                @include media-breakpoint-up(md) {
                    margin-inline-start: 2rem;
                    margin-top: 0;
                }
            }
        }
    }

    .aioa_dashboard-table {
        .aioa_dashboard-table-tr {
            .aioa_dashboard-table-td {
                
                .aioa_dashboard-order-domain {
                    word-wrap: anywhere;
                }

                .aioa_dashboard-order-list-action-btns {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .btn {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &+.btn {
                            margin-inline-start: 1rem;
                        }
                    }
                }
            }
        }
    }
}