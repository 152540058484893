// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";


.aioa_dashboard-sidebar-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {


            a {
                display: flex;
                align-items: center;
                position: relative;
                font-weight: $font-weight-medium;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                background-color: transparent;
                text-decoration: none;
                color: $body-color;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }

                .nav-link-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-inline-end: 0.5rem;
                    flex: 0 0 auto;
                }

                .nav-link-text {
                    flex: 1 0 auto;
                    position: relative;
                    width: calc(100% - 2rem);

                    &::after {
                        content: "";
                        position: absolute;
                        right: -1.5rem;
                        top: 0;
                        bottom: 0;
                        width: 5px;
                        height: 100%;
                        background-color: transparent;
                        border-radius: 5px;
                    }
                }

                &:hover,
                &.active {
                    color: $primary;

                    .nav-link-text {
                        &::after {
                            background-color: $primary;
                        }
                    }
                }
            }

            .dropdown {
                .btn {
                    background-color: transparent !important;
                    border-radius: 0;
                    color: $body-color;
                    padding: 0.5rem 0;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    font-weight: $font-weight-medium;
                    width: 100%;
                    white-space: normal;

                    .nav-link-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        margin-inline-end: 0.5rem;
                        flex: 0 0 auto;
                    }

                    .nav-link-text {
                        //flex: 1 0 auto;
                        position: relative;
                        text-align: left;
                        width: calc(100% - 2rem);

                    }

                    &::after {
                        flex: 0 0 auto;

                    }
                }

                .addon-dropdown-menu {
                    &.show {
                        display: block;
                        margin-top: 0 !important;
                        position: relative !important;
                        transform: translate(0) !important;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        background-color: transparent;

                    }

                    .dropdown-item {
                        background-color: transparent;
                        padding: 0;
                        padding-inline-start: 2.1rem;

                        a {
                            display: block;
                            white-space: pre-line;
                        }

                        &+.dropdown-item {
                            margin-top: 0.5rem;
                        }
                    }
                }
            }

            &+li {
                margin-top: 1rem;
            }

            &.support-btn {
                @media (min-width: 1700px) {
                    display: none;
                }
            }

        }
    }
}