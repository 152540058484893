// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-header {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 99;

    &.not-logged-in {
        padding-top: 2rem;
        padding-bottom: 2rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
    }

    @include media-breakpoint-up(xxl) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &.sticky {
        background-color: $body-bg;
    }

    .aioa_dashboard-header-domain-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
        }

        @include media-breakpoint-up(xxl) {
            justify-content: flex-start;
            margin-top: 0;
        }

        .aioa_dashboard-logo {
            img {
                width: 340px;
            }
        }
    }

    .aioa_dashboard-header-user-main-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .aioa_dashboard-navigation-toggle {
            margin-inline-start: 1.25rem;

            .aioa_dashboard-navigation-toggler-btn {
                &.btn {
                    display: flex;
                    flex-direction: column;
                    padding: 0.5rem 0.7rem;

                    @include media-breakpoint-up(sm) {
                        padding: 0.8rem 1rem;
                    }

                    .aioa_dashboard-navigation-toggler-icon {
                        width: 1.5rem;
                        height: 2px;
                        background-color: $white;

                        @include media-breakpoint-up(sm) {
                            width: 2rem;
                        }

                        &+.aioa_dashboard-navigation-toggler-icon {
                            margin-top: 7px;

                            @include media-breakpoint-up(sm) {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }

        .aioa_dashboard-logo {
            display: inline-flex;

            @include media-breakpoint-up(xxl) {
                display: none;
            }

            img {
                width: 240px;

                @include media-breakpoint-up(sm) {
                    width: 340px;
                }
            }
        }

        .aioa_dashboard-header-user-wrapper {
            display: flex;
            align-items: center;
        }
    }
}