// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa-dashboard-profile-content {
    .card {
        --bs-card-spacer-y: 1.5rem;
        --bs-card-spacer-x: 1.5rem;
        --bs-card-cap-padding-y: 1.5rem;
        --bs-card-cap-padding-x: 1.5rem;
        //--bs-card-cap-bg: rgba(var(--bs-primary-bg-subtle), 1);

        .card-header {
            //background-color: rgba($primary, 0.2);
            background-color: var(--bs-primary-bg-subtle);

            h2 {
                color: var(--bs-primary-text-emphasis);
            }
        }
    }

    .input-group.input-group-outline {
        &.phone-number {
            .form-control {
                width: 100%;
                height: 48px;
            }
        }
    }
}