// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";


.aioa_dashboard-violations-graph {
    .aioa_dashboard-violations-graph-box {
        border-radius: 10px;
        background-color: $white;
        border: 1px solid rgba($black, 0.1);
        overflow: hidden;

        @media (max-width: 1365.99px) {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        @media (min-width: 1366px) {
            padding: 2rem;
            text-align: center;
        }

        .aioa_dashboard-violations-graph-box-title {
            margin-bottom: 0;
            padding: 1rem 1.5rem;
            border-bottom: 1px solid rgba($black, 0.1);
            background-color: rgba($primary, 0.1);

            @media (max-width: 1365px) {
                height: 100%;
                display: flex;
                align-items: center;
            }

            @media (min-width: 1366px) {
                margin-bottom: 1.5rem;
                padding: 0;
                background-color: transparent;
                border: none;
            }
        }

        .aioa_dashboard-violations-semi-donut-graph {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
            padding: 1.5rem;

            @media (min-width: 1366px) {
                --percentage: 0;
                --fill: #ff0;
                width: 280px;
                height: 140px;
                position: relative;
                overflow: hidden;
                margin-inline: auto;
                align-items: center;
                padding: 0;
            }

            @media (min-width: 1366px) and (max-width: 1499.99px) {
                width: 224px;
                height: 112px;
            }


            &:after {
                @media (min-width: 1366px) {
                    content: '';
                    width: 280px;
                    height: 280px;
                    border: 30px solid;
                    border-color: rgba(0, 0, 0, 0.06) rgba(0, 0, 0, 0.06) var(--fill) var(--fill);
                    position: absolute;
                    border-radius: 50%;
                    left: 0;
                    top: 0;
                    box-sizing: border-box;
                    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
                    animation: fillAnimation 1s ease-in;
                }

                @media (min-width: 1366px) and (max-width: 1499.99px) {
                    width: 224px;
                    height: 224px;
                }
            }

            .aioa_dashboard-violations-graph-box-per {
                margin-bottom: 0;
                position: relative;
                z-index: 1;
            }

            .aioa_dashboard-violations-graph-box-info {
                margin-top: 0.4rem;
                position: relative;
                z-index: 1;
                font-weight: normal;
                color: rgba($black, 0.5);

                &.bold-text {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}

.aioa_dashboard-violations-list {
    border-radius: 10px;
    background-color: $white;

    @include media-breakpoint-up(md) {
        border: 1px solid rgba($black, 0.1);
    }

    .aioa_dashboard-violations-list-title-wrapper {

        @include media-breakpoint-up(lg) {
            padding: 1rem 2rem;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }

        .aioa_dashboard-violations-list-title {
            @include media-breakpoint-down(lg) {
                padding: 1rem 1.5rem;
                border-bottom: 1px solid rgba($black, 0.1);
                background-color: rgba($primary, 0.1);
            }
        }

        .aioa_dashboard-violations-list-tab {
            padding: 1rem 1.5rem;

            @media (max-width: 479.98px) {
                width: 100%;
            }

            @include media-breakpoint-up(lg) {
                padding: 0;
            }

            ul {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 0;
                padding: 0;
                list-style: none;
                border: 1px solid rgba($primary, 0.5);
                border-radius: 5px;

                @media (min-width: 480px) {
                    flex-direction: row;
                }

                li {
                    padding: 0.5rem;
                    background-color: rgba($primary, 0.1);
                    flex: 1 0 auto;

                    @media (max-width: 479.98px) {
                        width: 100%;
                    }

                    .btn {
                        width: 100%;

                        &:not(.active) {
                            background-color: transparent;
                            color: $black;
                            border-color: transparent;
                            font-weight: $font-weight-normal;
                        }
                    }
                }
            }
        }
    }

    .aioa_dashboard-table {
        .aioa_dashboard-table-tr {
            @include media-breakpoint-down(md) {
                margin-top: 0.8rem;
                border: 1px solid rgba($black, 0.1);
                background-color: rgba(240, 245, 249, 0.6);
            }

            .aioa_dashboard-table-td {
                @include media-breakpoint-down(md) {
                    .aioa_dashboard-table-td-cell-title {
                        width: 180px;
                    }
                }

                &.tcw-auto {
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        border-bottom: 1px solid rgba($black, 0.05);

                        & .aioa_dashboard-table-td-cell-title {
                            width: 100%;
                            padding-inline-end: 0;
                        }
                    }
                }
            }
        }
    }
}

.aioa_dashboard-violations-report-wrapper {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: $body-bg;
    z-index: 1;
}