// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.website-init-msg {
    position: relative;

    .aioa_dashboard-init-msg-block {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: transparent linear-gradient(117deg, rgba($white, 0.70) 0%, rgba($white, 0.8) 100%) 0% 0% no-repeat padding-box;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        z-index: 2;
        padding: 1.5rem;
        @media screen and (min-width:768px) {
            padding: 3rem;
        }

        .aioa_dashboard-init-msg-block-text {
            font-weight: 500;
            font-size: $font-size-lg;
            margin-bottom: 1.5rem;
        }
    }
}