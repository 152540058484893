@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";


.aioa_dashboard-addon-page-right-part {
    margin-top: 1.5rem;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        display: flex;
        align-items: center;
    }

    .badge {
        font-size: $font-size-base;
        --bs-badge-padding-x: 1rem;
        --bs-badge-padding-y: 0.5rem;
    }

    .addon-active-plan-info {
        display: flex;
        align-items: center;
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
        color: $success;

        @include media-breakpoint-up(lg) {
            margin-inline-end: 1rem;
        }

        .addon-plan-info {
            margin-inline-end: 0.5rem;
        }
    }

    .btn {
        margin-inline-start: 1rem;
    }
}

.aioa_dashboard-addon-details {
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: $white;

    .aioa_dashboard-plan-status-alert.alert-danger {
        --bs-alert-color: var(--bs-white);
        --bs-alert-bg: var(--bs-danger);
        --bs-alert-border-color: var(--bs-danger);
        --bs-alert-link-color: var(--bs-white);
        --bs-alert-margin-bottom: 0;
        border-radius: 10px;
        margin-top: 1.5rem;
    }

    .addon-description {
        font-weight: $font-weight-medium;
    }
}

.aioa_dashboard-addon-plan-list {
    margin-top: 1.5rem;

    .aioa_dashboard-widget-plan-box {
        &.card {
            --bs-card-cap-padding-y: 1.5rem;
            --bs-card-cap-padding-x: 1.5rem;
            --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
            --bs-card-cap-bg: #F0F5F9;
            --bs-card-border-radius: 1rem;
            --bs-card-inner-border-radius: 1rem;
            --bs-card-border-width: 0;
            box-shadow: 0 10px 0px $primary;
            position: relative;
            margin-bottom: 10px;
            height: 100%;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 3px 6px rgba($black, 0.15);
                border-radius: 1rem;
                border: 1px solid rgba($black, 0.05);
            }


            .card-header {
                position: relative;
                border-bottom: 1px solid rgba($black, 0.05);
            }

            .card-body {
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: column;

                .aioa_dashboard-widget-plan-box-description {
                    height: 100%;

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        li {
                            position: relative;
                            padding-inline-start: 1rem;

                            &+li {
                                margin-top: 5px;
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                top: 8px;
                                left: 0;
                                width: 6px;
                                height: 6px;
                                border-radius: 100%;
                                background-color: $black;
                            }
                        }

                        &.two-col-list {
                            @include media-breakpoint-up(xl) {
                                display: flex;
                                flex-wrap: wrap;
                            }

                            li {
                                width: 100%;
                                @include media-breakpoint-up(xl) {
                                    width: 50%;
                                }

                                flex: 0 0 auto;
                                margin-top: 5px;
                            }
                        }
                    }
                }

                .aioa_dashboard-widget-plan-domain-description {
                    margin-top: 0.5rem;
                }

                .aioa_dashboard-widget-plan-box-price {
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;

                    .aioa_dashboard-widget-plan-box-strike-price {
                        font-size: 1.125rem;
                        font-weight: $font-weight-medium;
                        color: $danger;
                    }

                    .aioa_dashboard-widget-plan-box-offer-price {
                        font-size: 1.25rem;
                        font-weight: $font-weight-bold;
                        color: $primary;
                    }

                    span {}
                }

                .aioa_dashboard-widget-plan-box-btn {
                    .btn {
                        &.btn-lg {
                            --bs-btn-border-radius: 2rem;
                            --bs-btn-padding-x: 2rem;
                            color: $primary;

                            &:hover,
                            &:focus {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }


        &.all-plans-details {
            margin-top: 1.5rem;

            @include media-breakpoint-up(xl) {
                margin-top: -0.7rem;
            }

            &.card {
                @include media-breakpoint-up(xl) {
                    --bs-card-cap-bg: var(--bs-primary);
                    --bs-card-inner-border-radius: 0;
                    --bs-card-bg: var(--bs-primary);
                    box-shadow: 0 0 0px $primary;
                    margin-bottom: 0;
                    color: $white;
                    a{
                        color: $white;
                    }
                }

                .card-header {
                    text-align: center;

                    @include media-breakpoint-up(xl) {
                        border-bottom-color: rgba($white, 0.20);
                    }
                }

                .card-body {
                    .aioa_dashboard-widget-plan-box-description {
                        ul {
                            li {
                                @include media-breakpoint-up(xl) {
                                    padding-inline-end: 1rem;
                                }

                                &::before {
                                    @include media-breakpoint-up(xl) {
                                        background-color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



.aioa_dashboard-addon-report-list {
    border-radius: 10px;
    background-color: $white;
    border: 1px solid rgba($black, 0.1);
    margin-top: 1.5rem;

    .aioa_dashboard-addon-report-filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 2rem;
    }

    .aioa_dashboard-addon-report-list-filter-wrapper {

        display: flex;
        align-items: center;

        &.sticky {
            /* position: sticky;
            top: 124px;
            background-color: $body-bg; */
        }

        .aioa_dashboard-addon-report-list-filter-block {
            display: flex;
            align-items: center;

            .form-label {
                margin-bottom: 0;
                flex: 0 0 auto;
                margin-inline-end: 0.5rem;
            }

            &+.aioa_dashboard-addon-report-list-filter-block {
                margin-inline-start: 2rem;
            }
        }
    }

    .aioa_dashboard-table {
        .report-action {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;

                &+.btn {
                    margin-inline-start: 0.5rem;
                }
            }
        }
    }
}