// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-multi-step-form-header {
    position: relative;
    margin-bottom: 1.5rem;

    .aioa_dashboard-multi-step-form-header-line {
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
        background-color: $gray-400;
        border-radius: 2px;
    }

    .aioa_dashboard-multi-step-form-step-list {
        display: flex;
        justify-content: space-around;
        position: relative;

        .form-step-number-box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .form-step-number {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                border: 2px solid $white;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                background-color: $gray-400;
            }

            &.active {
                .form-step-number {
                    background-color: $primary;
                    color: $white;
                }

                .form-step-name {
                    color: $primary;
                    font-weight: $font-weight-medium;
                }
            }

            &.success-active {
                .form-step-number {
                    background-color: $success;
                    color: $white;
                }

                .form-step-name {
                    color: $success;
                }
            }
        }
    }
}

.aioa_dashboard-multi-step-form-step-content {
    .aioa_dashboard-multi-step-form-title {
        margin-bottom: 1rem;
    }
}


// Bulk Domain
.modal-backdrop {
    &.aioa_dashboard-info-modal-overlay {
        --bs-backdrop-zindex: 1056;

        &.show {
            opacity: 1;
            background: transparent linear-gradient(117deg, rgba($white, 0.70) 0%, rgba(212, 212, 212, 0.8) 100%) 0% 0% no-repeat padding-box;
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
        }
    }
}

.modal {
    &.aioa_dashboard-info-modal {
        --bs-modal-zindex: 1057;
    }
}

.bulk-domain-modal-button {
    margin-inline-start: 1rem;
}

.aioa_dashboard-bulk-domain-wrapper {
    /* padding-top: 1rem;
    padding-bottom: 1rem; */

    .step-submit-btn {
        justify-content: center;
        display: flex;

        .btn {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .material-icons {
                font-size: 1.5rem;
            }
        }
    }

    .aioa_dashboard-bulk-domain-modal-screen-01 {

        .aioa_dashboard-bulk-domain-modal-sample-download {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: $h5-font-size;

            .aioa_dashboard-bulk-domain-modal-sample-download-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-end: 0.5rem;
            }

            .download-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                outline: none;

                .btn-icon {
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($primary, 0.1);
                    border-radius: 0.3rem;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        

        .aioa_dashboard-bulk-domain-upload-form {
            margin-top: 1.5rem;

            .upload-form-input {
                position: relative;

                .logo-upload-input {
                    position: relative;
                    border: 3px solid #eaeaea;
                    border-radius: 0.375rem;

                    &.error {
                        border-color: $danger;
                    }

                    &.success {
                        border-color: $success;
                    }

                    .form-label {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0.75rem;
                        color: $white;
                    }

                    .form-control {
                        height: 4rem;
                        opacity: 0;
                    }
                }

                .file-url {
                    position: absolute;
                    top: 50%;
                    left: 15rem;
                    transform: translateY(-50%);
                    max-width: calc(100% - 16rem);
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .spinner-border {
                    position: absolute;
                    right: 1rem;
                    top: 32%;
                }
            }
        }
    }

    .aioa_dashboard-bulk-domain-modal-screen-02 {
        .aioa_dashboard-table {
            border: 1px solid rgba(0, 0, 0, 0.1);

            .aioa_dashboard-table-thead {
                padding-inline: 0;
            }

            .aioa_dashboard-table-body {
                padding-inline: 0;
            }

            .aioa_dashboard-table-tr {
                .original-price {
                    color: $danger;
                }

                .btn {
                    &.action-btn {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &.final-price {
                    background-color: #F0F5F9;
                }
            }
        }

        .aioa_dashboard-bulk-domain-modal-pay-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.5rem 1.5rem 0;
        }
    }
}