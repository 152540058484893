// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.report-problem-form-wrapper {
    border: none;

    .card-body {
        padding: 40px;
        background-color: white;
        border-radius: 10px;
    }

    .form-select,
    .form-control {
        background-color: #F6F6F6;
        border-color: #C4C4C4;
    }
}

.custom-file-control {
    position: relative;

    .form-control {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .fake-input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $input-padding-y $input-padding-x;
        font-family: $input-font-family;
        @include font-size($input-font-size);
        font-weight: $input-font-weight;
        line-height: $input-line-height;
        color: $input-color;
        appearance: none; // Fix appearance for date inputs in Safari
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;

        // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
        @include border-radius($input-border-radius, 0);

        @include box-shadow($input-box-shadow);
        @include transition($input-transition);
    }
}