// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-support-btn {
    &.btn {
        &.btn-outline-primary {
            background-color: transparent;
            display: flex;
            align-items: center;
            padding: 0.625rem 1rem;
            border-radius: 0.625rem;
            font-size: $font-size-base;
            line-height: normal;
            color: $black;
            font-weight: $font-weight-normal;
            border: 1px solid $primary;
            margin-inline-end: 0.8rem;

            @media (max-width: 1699.99px) {
                display: none;
            }

            .aioa_dashboard-support-icon {
                width: 36px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-end: 1rem;

                img {
                    object-fit: cover;
                    width: 46px;
                    height: 30px;
                }
            }

            &:hover,
            &:focus {
                background-color: rgba($primary, 0.1);
                color: $black;
            }
        }
    }
}