// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-custom-domain-filter-select {
    font-size: 0.875rem;
    font-weight: 400;
    color: #495057;
    line-height: 1.5rem;
    min-width: 230px;
    width: 100%;

    & .css-13cymwt-control,
    & .css-t3ipsp-control {
        height: 38px;

        .css-1fdsijx-ValueContainer {
            padding: 0 1rem;
        }
    }
}