// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa-dashboard-no-record-screen {
    padding: 2rem;

    img {
        max-width: 13rem;
    }
}