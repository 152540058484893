// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-website-list {
    border-radius: 10px;
    background-color: $white;
    border: 1px solid rgba($black, 0.1);

    .aioa_dashboard-website-list-filter-wrapper {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;


        @include media-breakpoint-up(xxxl) {
            justify-content: flex-end;
            flex-wrap: nowrap;
            padding: 1.5rem 2rem;
        }

        .aioa_dashboard-website-list-filter-block {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(xxxl) {
                width: 50%;
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                padding-inline: 1rem;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
            }

            .form-label {
                margin-bottom: 0;
                flex: 0 0 auto;
                margin-inline-end: 0.5rem;
            }

            &+.aioa_dashboard-website-list-filter-block {
                @include media-breakpoint-up(xxxl) {
                    margin-inline-start: 2rem;
                }
            }
        }
    }

    .aioa_dashboard-table {
        .aioa_dashboard-table-tr {
            .aioa_dashboard-table-td {
                .domain-name {
                    font-weight: $font-weight-semibold;
                    display: inline-flex;
                    align-items: center;
                    word-break: break-all;

                    .domain-verify-icon {
                        width: 1.5rem;
                        height: auto;
                        display: inline-flex;
                        margin-inline-end: 0.5rem;
                        flex: 0 0 auto;
                    }
                }

                .email-filed {
                    word-break: break-all;

                    span {
                        font-weight: $font-weight-semibold;
                    }
                }

                .link-btn-group {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;

                    .btn {
                        text-decoration: none;

                        &+.btn {
                            margin-inline-start: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    &.aioa_dashboard-partner-website-list-wrapper {
        .aioa_dashboard-table {
            .aioa_dashboard-table-tr {
                .aioa_dashboard-table-td {
                    .btn {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}