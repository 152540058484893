// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";
@import "../helpers/custom_mixin.scss";

.aioa_dashboard-sidebar {
    flex: 0 0 auto;
    width: 330px;
    padding-top: 3rem;
    padding-bottom: 2rem;
    display: none;
    flex-direction: column;
    background-color: $white;
    max-height: 100vh;

    @include media-breakpoint-down(xxl) {
        position: fixed;
        top: 0;
        right: 0;
        background-color: $white;
        z-index: 9999;
        width: 360px;
        max-width: 100%;
        height: 100vh;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    @include media-breakpoint-up(xxl) {
        display: flex;
        position: sticky;
        top: 0;
        padding-top: 2rem;
    }

    @media (min-width: 1700px) {
        width: 350px;
    }

    .aioa_dashboard-sidebar-close-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        .btn-close {
            width: 2rem;
            height: 2rem;
            background-color: $danger;
            opacity: 1;
            flex: 0 0 auto;
            margin: 0;
            --bs-btn-close-bg: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>")
        }
    }

    .aioa_dashboard-sidebar-logo {
        padding-inline: 1.5rem;
        border-bottom: 1px solid rgba($black, 0.1);
        margin-bottom: 1.5rem;

        .aioa_dashboard-logo {
            height: 60px;
            display: flex;
            align-items: center;
        }

        .aioa_dashboard-plan-info {
            max-width: 240px;
            margin-inline: auto;
            margin-top: 2rem;
            margin-bottom: 2rem;
            /* display: flex;
            align-items: center;
            justify-content: center;*/
            text-align: center;
            border: 1px solid $primary;
            padding: 0.625rem;
            border-radius: 5px;
            overflow: hidden;

            &.partner-dashboard-btn {
                max-width: 100%;
                background-color: $primary;
                color: $white;
                text-decoration: none;
                font-weight: $font-weight-semibold;
                display: flex;

                .nav-link-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-inline-end: 0.5rem;
                }
            }
        }
    }

    .aioa_dashboard-sidebar-navigation {
        padding-inline: 1.5rem;
        overflow-y: auto;
        @include customScrollBars(8px, $primary);
    }
}


// Mobile Menu

.ada_dashboard-sidebar-navigation-open {
    .aioa_dashboard-sidebar {
        &.active {
            display: flex;
        }
    }
}