// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-getting-started-wrapper {
    background-image: url('../../img/add-domain-background.webp');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100vh;
    background-color: $white;

    .add-new-domain-screen-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        width: 100%;

        min-height: calc(100vh - 118px);

        @media (min-width: 576px) {
            min-height: calc(100vh - 120px);
        }

        @media (min-width: 992px) {
            min-height: calc(100vh - 77px);
        }

        @media (min-width: 1200px) {
            min-height: calc(100vh - 109px);
        }

        @media (min-width: 1600px) {
            min-height: calc(100vh - 118px);
            top: 118px;
            position: relative;
        }

        &:focus::before {
            display: none;
        }

        .add-new-domain-screen-btn-inner {
            text-align: center;

            img {
                max-width: 100%;
                width: auto;
                height: auto;
            }

            .add-new-domain-screen-btn-text {
                text-align: center;
                margin-top: 2rem;

                h1 {
                    margin: 0;
                    color: $primary;

                    @media (max-width: 576px) {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}