// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0;

    @include media-breakpoint-up(xxl) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    a {
        color: $black;
        padding: 0;
        margin: 0;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $primary;
        }
    }

    .aioa_dashboard-footer-nav {
        ul {
            li {
                &+li {
                    margin-inline-start: 1rem;

                    @include media-breakpoint-up(xxl) {
                        margin-inline-start: 1.5rem;
                    }
                }
            }
        }
    }
}

.aioa_dashboard-footer-nav {
    .nav {
        @media screen and (min-width:1440px) {
            justify-content: flex-end;
        }
    }
}