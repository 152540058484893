// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

@import "../helpers/custom_mixin.scss";

.aioa_dashboard-manage-plan {

    &.accordion {
        .accordion-item {

            .accordion-header {
                .accordion-button {
                    font-size: 1.25rem;
                    font-weight: $font-weight-medium;
                }
            }

            &+.accordion-item {
                margin-top: 2rem;
            }
        }
    }

    .aioa_dashboard-plan-info-box {
        &.card {
            padding: 0;
            background-color: #F0F5F9;
            border: 1px solid #CADAEA;

            .card-body {
                padding: 3rem 1rem 2rem;
                position: relative;

                @include media-breakpoint-up(md) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                @include media-breakpoint-up(md) {
                    padding: 3rem 2rem 2rem;
                }

                .aioa_dashboard-plan-info-box-label {
                    position: absolute;
                    top: 0;
                    left: 1rem;
                    //font-size: 16px;
                    line-height: normal;
                    padding: 0.5rem 2rem;
                    background-color: $primary;
                    color: $white;
                    font-weight: $font-weight-semibold;
                    border-radius: 0 0 2rem 2rem;

                    @include media-breakpoint-up(md) {
                        left: 2rem; 
                    }
                }

                .aioa_dashboard-plan-info-box-right {
                    margin-top: 1.5rem;

                    @include media-breakpoint-up(md) {
                        display: flex;
                        align-items: flex-end;
                        flex-direction: column;
                        justify-content: flex-end;
                        margin-top: 0;
                    }

                    .aioa_dashboard-plan-info-box-price {
                        .original-price {
                            font-size: 1.5rem;
                            font-weight: $font-weight-semibold;
                            color: $primary;

                            del {
                                display: inline-flex;
                                align-items: center;
                                color: $danger;
                                margin-inline-end: 0.8rem;
                            }

                            span {
                                font-size: 1rem;
                            }
                        }
                    }

                    .aioa_dashboard-plan-info-box-btn {
                        margin-top: 0.5rem;
                    }
                }
            }

            &.expired-plan-box {
                background-color: rgba($danger, 0.05);
                border: 1px solid rgba($danger, 0.2);

                .card-body {
                    .aioa_dashboard-plan-info-box-label {
                        background-color: $danger;
                    }
                }
            }

            &.trial-plan-box {
                background-color: rgba($warning, 0.05);
                border: 1px solid rgba($warning, 0.2);

                .card-body {
                    .aioa_dashboard-plan-info-box-label {
                        background-color: $warning;
                    }
                }
            }

            &.canceled-plan-box {
                background-color: rgba($black, 0.05);
                border: 1px solid rgba($black, 0.2);

                .card-body {
                    .aioa_dashboard-plan-info-box-label {
                        background-color: $black;
                    }
                }
            }

        }
    }

    .aioa_dashboard-upgrade-plan-info-box {
        margin-top: 2rem;

        &.card {
            padding: 0;
            background-color: #F0F5F9;
            border: 1px solid #CADAEA;

            .card-body {
                padding: 1rem;
                position: relative;

                @include media-breakpoint-up(md) {
                    padding: 2rem;
                }

                .aioa_dashboard-upgrade-plan-info-box-title {
                    @include media-breakpoint-down(md) {
                        text-align: center;
                    }
                }

                .aioa_dashboard-widget-plan-list {
                    margin-top: 2rem;

                    .aioa_dashboard-plan-list-toggle {
                        //margin: 0;

                        @include media-breakpoint-up(md) {
                            position: absolute;
                            top: 2rem;
                            right: 2rem;
                            margin: 0;
                        }
                    }

                    .row {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }


}