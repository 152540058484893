// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-user-dropdown-btn {
    &.dropdown {
        display: none;

        @include media-breakpoint-up(sm) {
            display: inline-flex;
        }

        margin-inline-start: 1.25rem;

        @media (min-width: 1500px) {
            margin-inline-start: 2rem;
        }

        .btn {
            &.btn-primary {
                background-color: transparent;
                border-color: transparent;
                color: $black;
                display: flex;
                align-items: center;
                padding: 0;
                box-shadow: none;

                &::after {
                    display: none;
                }

                .aioa_dashboard-user-dropdown-btn-wrapper {
                    display: flex;
                    align-items: center;

                    .aioa_dashboard-user-dropdown-btn-icon {
                        width: 52px;
                        height: 52px;
                        border-radius: 100%;
                        background-color: #545769;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $white;
                        font-size: 2.5rem;
                    }

                    .aioa_dashboard-user-dropdown-btn-text {
                        display: none;

                        @media (min-width: 1366px) {
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            flex-direction: column;
                            margin-inline-start: 0.5rem;
                            text-align: left;
                        }

                        .aioa_dashboard-user-dropdown-btn-user-name {
                            font-weight: $font-weight-bold;
                            font-size: $font-size-lg;
                            line-height: normal;
                            width: 120px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (min-width: 1500px) {
                                width: 150px;
                            }
                        }

                        .aioa_dashboard-user-dropdown-btn-company-name {
                            font-weight: $font-weight-normal;
                            width: 120px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (min-width: 1500px) {
                                width: 150px;
                            }
                        }
                    }
                }

            }
        }

        .dropdown-menu {
            width: 100%;
            padding: 0;

            .dropdown-divider {
                @media (min-width: 1366px) {
                    display: none;
                }
            }

            .dropdown-item {
                padding: 0.5rem 1.5rem;
                display: flex;
                align-items: center;
                font-size: $font-size-base;
                font-weight: $font-weight-medium;
                margin-top: 0.5rem;

                i {
                    margin-inline-end: 0.5rem;
                }

                &.logout-btn {
                    background-color: $danger;
                    color: $white;
                }


                &.aioa_dashboard-user-dropdown-btn-text {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    text-align: left;


                    @media (min-width: 1366px) {
                        display: none;
                    }

                    .aioa_dashboard-user-dropdown-btn-user-name {
                        font-weight: $font-weight-bold;
                        font-size: $font-size-lg;
                        line-height: 1;
                        width: 120px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media (min-width: 1500px) {
                            width: 150px;
                        }
                    }

                    .aioa_dashboard-user-dropdown-btn-company-name {
                        font-weight: $font-weight-normal;
                        width: 120px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media (min-width: 1500px) {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}